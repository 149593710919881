@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
*{
    margin: 0;
    padding: 0;

    font-family: 'Roboto', sans-serif;
}
.nav-bg{
    background-image: url('../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;

}
.ul-n{
    display: flex;
    gap: 2rem;
    border: 2px solid #bc0000;
    padding: 10px 140px;
    border-radius: 20px;
    background-color: #bc0000;
    white-space: pre;
    color: white;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.nav-a{
    color: white;
}
a{
    /* color:black;  */
    text-decoration:none;
}
.nav-logo{
       cursor: pointer;
    width: 200px;
}
.fle1-nav{

    padding: 10px 20px;
    display: flex;

    align-items: center;
    justify-content: space-between;
}
.login-b{
    cursor: pointer;
    border: none;
    font-size: 18px;
    background: transparent;
    color: #bc0000;
    font-weight: bold;

}
.nav-bb{

    display: flex;
    gap: 1rem;
}
.sign-bb{
    color: white;
    background-color: #bc0000 ;
    padding: 7px 10px;
    font-size: 15px;
    border: none;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}

@media only screen and (max-width: 950px) {



    .fle1-nav {
        padding: 10px 20px;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .ul-n {
        font-weight: bold;
        display: flex;
        gap: 2rem;
        border: 2px solid #bc0000;
        padding: 10px 60px;
        font-size: 10px;
        border-radius: 20px;
        background-color: #bc0000;
        white-space: pre;
        color: white;
        align-items: center;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    }
    
    
    .pdf-about-pages>img {
        width: 315px;
    }
    .img-home-g {
        width: 362px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .img-home-g1{
        width: 362px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .img-home-g2{
        width: 362px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .login-b {
        cursor: pointer;
        border: none;
        font-size: 15px;
        background: transparent;
        color: #bc0000;
        font-weight: bold;
    }
    .sign-bb {
        font-weight: bold;
        color: white;
        background-color: #bc0000;
        padding: 7px 10px;
        font-size: 13px;
        border: none;
        border-radius: 15px;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        cursor: pointer;
    }
}
@media only screen and (max-width: 800px) {

.ul-n {
gap: 1.5rem;
}
.about-pp2 {
    font-size: 20px;
    text-align: center;
}

.about-pp21 {
    text-align: center;

}
}