.bg-contact{
    background-image: url('../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;
min-height: 618px;
}

.contact-h1{
    font-size: 20px;
}
.contact-h2{
    font-size: 20px;
}
.contact-h3{
    font-size: 20px;
}
.head-pp{
    /* height: 418px; */
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}
/* .load{
    border: 16px solid black;
    border-radius: 50%;
    border-top: 16px solid white;
    width: 120px;
    height: 120px;
    animation:  rotate 2s linear infinite;

}
@keyframes rotate{
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}


} */

