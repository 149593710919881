.head-about1 {
  /* padding: 14px 0px; */
}
.about-h1 {
  color: #bc0000;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}
.bg-blog {
  background-image: url("../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  min-height: 618px;

  /* height: 89vh; */
}
/* .w-o1{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .w-oo1{
        margin-top: 20px;
        width: 80%;
        gap: 2rem;
        display: flex;
        flex-direction: column;
    }
    .para-about21{
    margin-top: 30px;
    padding-bottom: 98px;
    }
    .about-pp21{
        font-size: 20px;
    }

     */
.blog-h2 {
  font-size: 20px;
}
.blog-y {
  display: flex;
  justify-content: center;
}
.blo-logo {
  width: 200px;
}
.blo-kk {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}
.intro-o {
  font-size: 15px;
  padding: 10px 20px;
}
.paf-g {
  padding-bottom: 50px;
}
.blo-int {
  display: flex;
  padding: 20px 80px;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 870px) {
  .blo-int {
    display: flex;
    padding: 20px;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.react-tabs__tab--selected {
  background: unset !important;
  border-color: unset !important;
  color: white !important;
  border-radius: unsetx !important;
}

.react-tabs__tab {
  /* display: inline-block; */
  /* border: 1px solid transparent; */
  /* border-bottom: none; */
  bottom: -1px;
  /* position: relative; */
  /* list-style: none; */
  /* padding: 6px 12px; */
  /* cursor: pointer; */
}
/* .tab-panel-i{
    display: flex;
    justify-content: flex-end;
} */
.tab-oi {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.react-tabs {
  /* display: flex; */
  /* align-items: center; */
}

.tab-1 {
  color: white;
  font-size: 13px;
  text-align: center;
  /* padding: 15px 0px; */
  cursor: pointer;
  line-height: 1.5;
}
.tab-s-p {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 20px;
  align-content: center;
}

.tab-1:focus {
  border: 2px solid white;
  padding: 5px;
  border-radius: 10px;
}
.tab-1:hover {
}

@media only screen and (max-width: 1050px) {
  .blo-int {
    /* padding: 20px 0px; */
  }
  /* .tab-oi {
   width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
    } */
  /* .tab-panel-i {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    } */
}

@media only screen and (max-width: 930px) {
  .sidebar {
    /* display: none ; */
  }
  /* .tab-panel-i {
        display: flex;
        align-items: center;
        justify-content: center;
    } */
}

.blo-int > p {
  font-size: 16px;
}
.hr-blog {
  border: 0.5px solid white;
}
.svg-open {
  padding: 10px;
  font-size: 30px;
}

@media only screen and (max-width: 1054px) {
  .i-frame-o {
    width: 100% !important;
  }
}
.iframe-w-v {
  width: 100%;
}
.i-frame-o {
  width: 60%;
}

.fle-main-div {
  display: flex;
}
/* Follow me for more pens like this! */

/* Tweak to change the look and feel */
:root {
  --dropdown-highlight: dodgerblue;
  --dropdown-width: 160px;
  --dropdown-background: white;
  --dropdown-color: black;
}

/* Center the planet */
.bodys {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Boring button styles */
a.button {
  /* Frame */
  display: inline-block;

  box-sizing: border-box;

  /* Style */
  border: none;

  font-size: 24px;
  cursor: pointer;
}

a.button:active {
  filter: brightness(75%);
}

/* Dropdown styles */
.dropdown {
  position: relative;
  padding: 0;
  margin-right: 1em;

  border: none;
}

.dropdown summary {
  list-style: none;
  list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
  display: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown summary:focus a.button {
  
}

.dropdown summary:focus {
  outline: none;
}

.dropdown ul {
  position: absolute;
  margin: 20px 0 0 0;
  padding: 20px 0;
  width:  160px;
  left: 50%;
  margin-left: calc((var(--dropdown-width) / 2) * -1);
  box-sizing: border-box;
  z-index: 2;

  background: var(--dropdown-background);
  border-radius: 6px;
  list-style: none;
}

.dropdown ul li {
  padding: 0;
  margin: 0;
}

.dropdown ul li a:link,
.dropdown ul li a:visited {
  display: inline-block;
  padding: 10px 0.8rem;
  width: 100%;
  box-sizing: border-box;

  color: var(--dropdown-color);
  text-decoration: none;
}

.dropdown ul li a:hover {
  background-color: dodgerblue;
  color: var(--dropdown-background);
}

/* Dropdown triangle */
.dropdown ul::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--dropdown-background) transparent;
}

/* Close the dropdown with outside clicks */
.dropdown > summary::before {
  display: none;
}

.dropdown[open] > summary::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
