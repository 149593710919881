.bg-hh{
background-image: url('../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;

}
.main-div-h-d{
    display: flex;
}

.img-home-g{
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.img-home-g1{
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.img-home-g2{
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.home-p-gg{
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* margin: 30px 0px; */
    flex-wrap: wrap;
    /* margin: 30px 0px; */
}
.log-2{

    display: flex;
    flex-direction: column;
}
.home-logo{

    width: 200px;
}
.top-ii{

    margin-top: 40px;
}