
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600);

body{

}
.ul-bl>a{
  text-decoration: none;
  transition:all 0.6s ease;
  
  &:hover{
     transition:all 0.6s ease;
  }
}

.app{
  height: 100vh;
}
/* -------------
Sidebar
----------------*/
.sidebar {
  position: absolute;
  //width: 33.3333%;
  width: 100%;
  height: 100%;

  overflow:auto;
  // background-color: #19222a;
  -webkit-transform: translateZ(0);
  visibility: visible;
  -webkit-backface-visibility: hidden;
  

  background-color: #bc0000;

 
}

.head-h{
  
  display: flex;
  justify-content: center;
  margin-top: 20px;
color: white;

}
/* -------------
Sidebar Nav
----------------*/
.sidebar-nav {
  position: fixed;
  //width: 13em;
  width: 100%;
  background-color: #bc0000;
  height: 100%;
  font-weight: 400; 
  font-size: 1.2em;
  overflow: auto;
  padding-bottom: 6em;
  z-index: 9;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
 
  
  ul{
    list-style:none;
    display: block;
    padding: 0;
    margin: 0;
    
    li{
      margin-left: 0;
      padding-left: 0;
      //min-width: 13em;
      display:inline-block;
      width: 100%;
      
      a{
        color: rgba(255,255,255,0.9);
        font-size: 12px;
        padding: 1.05em	1em;
        position: relative;
        display:block;
        
        &:hover{
          // background-color: rgba(0,0,0,0.9);
          // transition:all 0.6s ease;
        }
      }
/* -------------
Sidebar: icons
----------------*/
      i{
        font-size: 1.8em;
        padding-right: 0.5em;
        width: 9em;
        display: inline;
        vertical-align:middle;
      }  
    }
  }

/* -------------
Chev elements
----------------*/ 
//   & > ul > li > a:after {


//   }
/* -------------
Nav-Flyout
----------------*/

/* -------------
hover
----------------*/

}
.tex-aa{
    text-align: center;
}

.li-u{
display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.li-u:hover {

    // background-color: rgba(0,0,0,0.9);
}
.tab-1:hover{
  // background-color: #000000e6;
  // transition:all 0.6s ease;
}
.svg-side{
  cursor: pointer;

}
.close-svg1{
color: white;
  font-size: 20px;
}
.close-div-svg{
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
